// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
//
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useLocales } from 'src/locales';
import { LoggedInUser } from 'src/types/user';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { IUserRecipe } from 'src/types/recipe';
import RecipeNewEditForm from './recipe-new-edit-form';



// ----------------------------------------------------------------------

export default function RecipeEditView() {
  const { t } = useLocales();
  const settings = useSettingsContext();
  const params = useParams();
  const { id } = params;
  const mockedUser = useMockedUser();
  const loggedInUser = mockedUser.user as LoggedInUser;
  const [recipeLoading, setRecipeLoading] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState<IUserRecipe | undefined>(undefined);


  const user = mockedUser.user as LoggedInUser;

  const shouldShowPricingView = () => {
    if (!user || !user.expiryDate) return false;
    if(user.expiryDate!=="NO") return true;

    return false;
  };
  const getRecipes = useCallback(async () => {
    setRecipeLoading(true);
    const pageFromstorage = localStorage.getItem('recipePage');
    localStorage.removeItem('recipePage');
    const page = pageFromstorage ? parseInt(pageFromstorage, 10) : 0;
    const data = {
      tenantrecipe: false,
      ingredients: [],
      tenant: loggedInUser.tenant_id,
      isdemo: shouldShowPricingView(),
      page,
      email: loggedInUser.email
    }
    const res = await axiosInstance.post(endpoints.recipe.list, data);
    setRecipeLoading(false);
    if (res.status === 200) {
      const integerId = parseInt(id as string, 10);
      const selectedRecipeFromResponse = res.data.recipes.find((recipe: any) => recipe.id === integerId);
      setSelectedRecipe(selectedRecipeFromResponse);
    }
  }, [loggedInUser.tenant_id, id]);
  useEffect(() => {
    getRecipes();
  }, [getRecipes]);
  if (recipeLoading) {
    return <LoadingScreen />
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t("EDIT_RECIPE")}
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: t("RECIPES"),
            href: paths.dashboard.recipe.root,
          },
          { name: t("EDIT_RECIPE") },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {
        selectedRecipe ? <RecipeNewEditForm selectedRecipe={selectedRecipe} /> : ""
      }
    </Container>
  );
}
