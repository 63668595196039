// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');

    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

type Auth = {
  accessToken: string | null;
  email: string | null;
};
export const getValueFromStorage = (): Auth => {
  const auth: Auth = {
    accessToken: null,
    email: null
  }
  const isRememberMe = localStorage.getItem('rememberMe');
  if (isRememberMe) {
    auth.accessToken = localStorage.getItem('accessToken');
    auth.email = localStorage.getItem('nEmail');
  } else {
    auth.accessToken = localStorage.getItem('accessToken');
    auth.email = localStorage.getItem('nEmail');
  }
  return auth;
}
export const setValueToStorage = (accessToken: string | null, email: string, rememberMe?: boolean) => {
  if (accessToken) {
    if (rememberMe) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('nEmail', email);
      localStorage.setItem('rememberMe', 'true');
    } else {
      sessionStorage.setItem('accessToken', accessToken);
      sessionStorage.setItem('nEmail', email);
      sessionStorage.removeItem('rememberMe');

      // added on 29 Aug to resolve issue of login
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('nEmail', email);
      localStorage.removeItem('rememberMe');
    }
    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);
  } else {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('nEmail');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('nEmail');
    localStorage.removeItem('rememberMe');
    sessionStorage.removeItem('rememberMe');
  }
}
export const setSession = (accessToken: string | null, email: string, rememberMe?: boolean) => {
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    setValueToStorage(accessToken, email, rememberMe);
  } else {
    setValueToStorage(null, "", rememberMe);
    delete axios.defaults.headers.common.Authorization;
  }
};
