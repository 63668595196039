import { m } from 'framer-motion';
import { useState, useCallback } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { _notifications } from 'src/_mock';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import { useLocales } from 'src/locales';
import NotificationItem from './notification-item';

import {IRecentResponse} from "../../../types/user";

// ----------------------------------------------------------------------


type Props = {
  recentresponses?: IRecentResponse[];
};


// ----------------------------------------------------------------------

export default function NotificationsPopover({recentresponses}:Props) {
  const drawer = useBoolean();
  const { t } = useLocales();

  const smUp = useResponsive('up', 'sm');

  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const notificationsConvert = (recentresponses || []).map(response => ({
    id: response.identification, // Assuming customer_id is unique and can be used as ID
    title: `${response.firstName} ${response.lastName} ${t("COMPLETED_CHECKIN")}`,
    category: 'chat',
    createdAt: new Date(response.response_date),
    isUnRead: true, // You can set this based on your requirement
    type: 'Communication',
    avatarUrl: null // You can set this based on your requirement
  }));



  const TABS = [
    {
      value: 'all',
      label: t('ALL'),
      count: notificationsConvert.length,
    }
    // {
    //   value: 'unread',
    //   label: 'Unread',
    //   count: 12,
    // },
    // {
    //   value: 'archived',
    //   label: 'Archived',
    //   count: 10,
    // },
  ];



  const [notifications, setNotifications] = useState(notificationsConvert);

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {t("NOTIFICATIONS")}
      </Typography>


      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notificationsConvert.length === 0 && (
            <Typography variant="subtitle2" sx={{ px: 2.5, py: 2.5, color: 'text.secondary' }}>
              {t("NO_NOTIFICATIONS")}
            </Typography>
            )}
        {notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}

        </Stack>

        <Divider />

        {renderList}

        <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            {t("VIEW_ALL")}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
