import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import { Button, Dialog, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import FormProvider, {
    RHFSelect,
    RHFTextField
} from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import axios, { endpoints } from 'src/utils/axios';
import { LoadingButton } from '@mui/lab';
import {LoggedInUser} from "../../../types/user";
import {useLocales} from "../../../locales";


type Props = {
    open: boolean;
    setOpenDialog: (open: boolean) => void;
}
export default function IngredientNewForm({ open, setOpenDialog }: Props) {

    const { t } = useLocales();
    const mockedUser = useMockedUser();
    const loggedInUser = mockedUser.user as LoggedInUser;
    // const router = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedunit, setUnit] = useState(0); // Initialize with a default value

    const handleUnitChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setUnit(event.target.value as number); // You may need to cast the value to the appropriate type
    };

    const NewIngredientSchmea = Yup.object().shape({
        name: Yup.string().required(t("ALERT_INGREDIENT_NAME_REQ")),
        calories: Yup.number()
            .transform((_, value) => {
                if (value.includes('.')) {
                    return null;
                }
                return +value.replace(/,/, '.');
            })
            .positive(),

        protein: Yup.number()
            .transform((_, value) => {
                if (value.includes('.')) {
                    return null;
                }
                return +value.replace(/,/, '.');
            })
            .positive(),
        carbonhydrates: Yup.number()
            .transform((_, value) => {
                if (value.includes('.')) {
                    return null;
                }
                return +value.replace(/,/, '.');
            })
            .positive(),
        fat: Yup.number()
            .transform((_, value) => {
                if (value.includes('.')) {
                    return null;
                }
                return +value.replace(/,/, '.');
            })
            .positive(),
        unit: Yup.number().required('Unit is required'),
        fiber: Yup.number()
            .transform((_, value) => {
                if (value === 0 || value === '0') {
                    return 0;
                }
                if (value.includes('.')) {
                    return null;
                }
                return +value.replace(/,/, '.');
            })
            .nullable(),
        added_by: Yup.number().required('Added by is required'),
    });

    const defaultValues = useMemo(
        () => ({
            name: '',
            calories: 0,
            protein: 0,
            carbonhydrates: 0,
            fat: 0,
            fiber: 0,
            unit: 1,
            category:8,
            added_by:loggedInUser.tenant_id
        }),
        [loggedInUser]
    );

    const methods = useForm({
        resolver: yupResolver(NewIngredientSchmea),
        defaultValues,
    });

    const [loading, setIsLoading] = React.useState<boolean>(false);
    const category = [
        {
            label:t("LABEL_STEP_MEAT"),
            value:0
        },
        {
            label:t("LABEL_STEP_FRUVEG"),
            value:1
        },
        {
            label:t("LABEL_STEP_CEREALS"),
            value:2
        },
        {
            label:t("LABEL_STEP_MILK"),
            value:3
        },
        {
            label:t("LABEL_STEP_OTHERS"),
            value:4
        },
        {
            label:t("LABEL_HERBS"),
            value:5
        },
        {
            label:t('LABEL_STEP_FISH'),
            value:6
        },
        {
            label:t("OWN_FOODS_CAT"),
            value:8
        }
    ];

    const UnitOptions = [
        {
            label: t("LABEL_ML"),
            value: 0,
        },
        {
            label: t("LABEL_GR"),
            value: 1,
        },
        {
            label: t("LABEL_UNIT"),
            value: 2,
        },
        {
            label: t("LABEL_SLICE"),
            value: 3,
        },
        {
            label: t("LABEL_EL"),
            value: 4,
        },
        {
            label: t("LABEL_TL"),
            value: 5,
        },
        {
            label: t("LABEL_CUP"),
            value: 6,
        },
        {
            label: t("LABEL_PINCH"),
            value: 7,
        },
        {
            label: t("LABEL_CLOVE"),
            value: 8,
        },
        {
            label: t("LABEL_PK"),
            value: 9,
        },
        {
            label: t("LABEL_TUB"),
            value: 10,
        },
        {
            label: t("LABEL_ROLL"),
            value: 11,
        },
        {
            label: t("LABEL_LEAVES"),
            value: 12,
        },
        {
            label: t("LABEL_BUNCH"),
            value: 13,
        },
        {
            label: t("LABEL_HEAD"),
            value: 14,
        },
    ];


    const {
        handleSubmit,
        formState: { isSubmitting },
        reset,
        getValues,
    } = methods;
    const onSubmit = handleSubmit(async (data) => {
        try {
            const dataToSend = {
                ...data,
                selectedunit
            };
            setIsLoading(true);
            const response = await axios.post(endpoints.recipe.addNewIngredient, dataToSend);
            setIsLoading(false);
            if (response.status === 200) {
                enqueueSnackbar(t("ALERT_INGREDIENT_ADDED"));
                reset();
                setOpenDialog(false);
                // router.push(paths.dashboard.recipe.root);
            } else {
                enqueueSnackbar('Something went wrong!!', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong!!', { variant: 'error' });
            console.error(error);
        }
    });
    const isMobile = window.innerWidth <= 768;
    const btnstyles = {
        gap: "12px",
        marginTop: "18px",
        display: isMobile ? "grid" : "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    };


    return (
        <div style={{ width: "800px" }}>
            <Dialog open={open} fullWidth>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Grid container spacing={3}>
                        <Grid xs={12} md={12}>
                            <Card sx={{ p: 3 }}>
                                <h3>{t("HEADING_CREATE_NEW_ENTRY")}</h3>
                                <Box
                                    rowGap={3}
                                    columnGap={2}
                                    display="grid"
                                    gridTemplateColumns={{
                                        xs: 'repeat(1, 1fr)',
                                        sm: 'repeat(2, 1fr)',
                                    }}
                                >
                                    <RHFTextField name="name" label={t("INPUT_FIELD_INGREDIENT_NAME")} />
                                    <RHFSelect
                                        name="unit"
                                        label={t("INGREDIENT_UNIT")}
                                        InputLabelProps={{ shrink: true }}
                                       onChange={handleUnitChange}
                                        value={selectedunit}
                                    >
                                        {UnitOptions.map(unit => (
                                            <MenuItem
                                                key={unit.value}
                                                value={unit.value}
                                            >
                                                {unit.label}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                    <RHFSelect
                                        name="category"
                                        label={t("CATEGORY")}
                                        InputLabelProps={{ shrink: true }}
                                    >
                                        {category.map(cat => (
                                            <MenuItem
                                                key={cat.value}
                                                value={cat.value}
                                            >
                                                {cat.label}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                    <RHFTextField name="calories"
                                                  label={
                                                      selectedunit === 0 || selectedunit === 1
                                                          ? `${t("LABEL_KALORIEN")} ${t("PER")} 100 ${UnitOptions[selectedunit].label}`
                                                          : `${t("LABEL_KALORIEN")} ${t("PER")} ${UnitOptions[selectedunit].label}`
                                                  }


                                    />
                                    <RHFTextField name="protein"
                                                  label={
                                                      selectedunit === 0 || selectedunit === 1
                                                          ? `${t("LABEL_PROTEIN")} ${t("PER")} 100 ${UnitOptions[selectedunit].label}`
                                                          : `${t("LABEL_PROTEIN")} ${t("PER")} ${UnitOptions[selectedunit].label}`
                                                  }

                                    />
                                    <RHFTextField name="carbonhydrates"
                                                  label={
                                                      selectedunit === 0 || selectedunit === 1
                                                          ? `${t("LABEL_CARBS")} ${t("PER")} 100 ${UnitOptions[selectedunit].label}`
                                                          : `${t("LABEL_CARBS")} ${t("PER")} ${UnitOptions[selectedunit].label}`
                                                  }

                                    />
                                    <RHFTextField name="fat"
                                                  label={
                                                      selectedunit === 0 || selectedunit === 1
                                                          ? `${t("LABEL_FAT")} ${t("PER")} 100 ${UnitOptions[selectedunit].label}`
                                                          : `${t("LABEL_FAT")} ${t("PER")} ${UnitOptions[selectedunit].label}`
                                                  }
                                    />
                                    <RHFTextField name="fiber"
                                                  label={
                                                      selectedunit === 0 || selectedunit === 1
                                                          ? `${t("LABEL_FIBER")} ${t("PER")} 100 ${UnitOptions[selectedunit].label}`
                                                          : `${t("LABEL_FIBER")} ${t("PER")} ${UnitOptions[selectedunit].label}`
                                                  }
                                    />
                                </Box>
                                <div style={btnstyles}>
                                    <LoadingButton loading={loading} type="submit" variant='contained' color='success'>
                                        {t("BUTTON_CREATE_NEW_ENTRY")}
                                    </LoadingButton>
                                    <Button variant='contained' color='error' onClick={() => {
                                        setOpenDialog(false);
                                    }}>
                                        {t("BUTTON_CANCEL")}
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </FormProvider>
            </Dialog>
        </div>
    );
}
