import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  isLogin?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false,isLogin, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

      const logo = (
          <Box
              component="img"
              src={theme.palette.mode !== 'light'?"/assets/images/ngwhiteak.png":"/assets/images/ng.png"} // => Your path in public folder
              sx={{
                  width: 60,
                  height: 60,
                  cursor: 'pointer',

                  '@media screen and (max-width: 600px)': { // Adjust the max-width as needed
                      margin: 'auto', // Center the logo horizontally
                      textAlign: 'center', // Center the logo vertically
                      width: '80px', // Adjust the width for mobile
                      height: '80px', // Adjust the height for mobile

                      ...(isLogin === false && { marginLeft: '30px' }),
                  },
                  ...sx,
              }}
          />
      );
      ;

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
