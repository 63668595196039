const NutritionValues = [
    {
        name: "LABEL_SODIUM",
        key: 'natrium',
        maxValue: 1500,
        maxValueFemale19to25: 1500,
        maxValueFemale25to51: 1500,
        maxValueFemale51to65: 1500,
        maxValueFemale65plus: 1500,
        maxValueMale19to25: 1500,
        maxValueMale25to51: 1500,
        maxValueMale51to65: 1500,
        maxValueMale65plus: 1500,
        OverdosePercentage: 500,
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_POTASSIUM",
        key: 'kalium',
        maxValue: 4000,
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_MAGNESIUM",
        key: 'magnesium',
        maxValue: 350,
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_CHLORIDE",
        key: 'chlorid',
        maxValue: 2300,
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_CALCIUM",
        key: 'calcium',
        maxValue: 1000,
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_PHOSPHORUS",
        key: 'phosphorus',
        maxValue: 700,
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_IRON",
        key: 'iron',
        maxValue: 12500,
        unit: 'µg',
        isTrace: true,
    },
    {
        name: "LABEL_IODINE",
        key: 'iodine',
        maxValue: 200,
        unit: 'µg',
        isTrace: true,
    },
    {
        name: "LABEL_FLUORINE",
        key: 'flour',
        maxValue: 3800,
        unit: 'µg',
        isTrace: true,
    },
    {
        name: "LABEL_ZINC",
        key: 'zink',
        maxValue: 8500,
        unit: 'µg',
        isTrace: true,
    },
    {
        name: "LABEL_MANGANESE",
        key: 'mangan',
        maxValue: 3500,
        unit: 'µg',
        isTrace: true,
    },
    //   {
    //     name:'Selenium',
    //     key:'selenium',
    //     maxValue:70
    //   },
    {
        name: "LABEL_VITAMIN_C",
        key: 'vitC',
        maxValue: 100000,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B1",
        key: 'vitB1',
        maxValue: 1100,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B2",
        key: 'vitB2',
        maxValue: 1200,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B3",
        key: 'vitB3',
        maxValue: 17000,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B5",
        key: 'vitB5',
        maxValue: 6000,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B6",
        key: 'vitB6',
        maxValue: 2000,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_ESSENTIELLEAMINOSAEUREN",
        key: 'EssentielleAminosaeuren',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_ISOLEUCIN",
        key: 'Isoleucin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_LEUCIN",
        key: 'Leucin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    },
    {
        name: "LABEL_VITAMIN_B7",
        key: 'vitB7',
        maxValue: 100,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B9",
        key: 'vitB9',
        maxValue: 400,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B12",
        key: 'vitB12',
        maxValue: 3,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_A",
        key: 'vitA',
        maxValue: 1000,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_D",
        key: 'vitD',
        maxValue: 20,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_E",
        key: 'vitE',
        maxValue: 12000,
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_K",
        key: 'vitK',
        maxValue: 70,
        unit: 'µg',
        isVitamin: true,
    }, {
        name: "LABEL_LYSIN",
        key: 'Lysin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_METHIONIN",
        key: 'Methionin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_CYSTEIN",
        key: 'Cystein',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_PHENYLALANIN",
        key: 'Phenylalanin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_TYROSIN",
        key: 'Tyrosin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_THREONIN",
        key: 'Threonin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_TRYPTOPHAN",
        key: 'Tryptophan',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_VALIN",
        key: 'Valin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_ARGININ",
        key: 'Arginin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_HISTIDIN",
        key: 'Histidin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_NICHTESSENTIELLEAMINOSAEUREN",
        key: 'NichtessentielleAminosaeuren',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_ALANIN",
        key: 'Alanin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_ASPARAGINSAEURE",
        key: 'Asparaginsaeure',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_GLUTAMINSAEURE",
        key: 'Glutaminsaeure',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_GLYCIN",
        key: 'Glycin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_PROLIN",
        key: 'Prolin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_SERIN",
        key: 'Serin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    },






    {
        name: "LABEL_ZUCKER",
        key: 'Zucker',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_MONOSACCHARIDE",
        key: 'Monosaccharide',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_GLUCOSE",
        key: 'Glucose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_FRUCTOSE",
        key: 'Fructose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_GALACTOSE",
        key: 'Galactose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_DISACCHARIDE",
        key: 'Disaccharide',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_SACCHAROSE",
        key: 'Saccharose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_MALTOSE",
        key: 'Maltose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_LACTOSE",
        key: 'Lactose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_OLIGOSACCARIDE_RESORBIERBAR",
        key: 'Oligosaccarideresorbierbar',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_OLIGOSACCARIDE_NICHT_RESORBIERBAR",
        key: 'Oligosaccaridenichtresorbierbar',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_FIBER",
        key: 'fiber',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
        isFiber: true,
    }, {
        name: "LABEL_GLYKOGEN",
        key: 'Glykogen',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_STAERKE",
        key: 'Staerke',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_ZUCKERALKOHOLE",
        key: 'Zuckeralkohole',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    },






    {
        name: "LABEL_GESAETTIGTE_FETTSAEUREN",
        key: 'GesaettigteFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_EINFACHUNGESAETTIGTEFETTSAEUREN",
        key: 'EinfachungesaettigteFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_MEHRFACHUNGESAETTIGTEFETTSAEUREN",
        key: 'MehrfachungesaettigteFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_OMEGA_3_FETTSAEUREN",
        key: 'Omega_3_Fettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_OMEGA_6_FETTSAEUREN",
        key: 'Omega_6_Fettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_KURZKETTIGE_FETTSAEUREN",
        key: 'KurzkettigeFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_MITTELKETTIGEFETTSAEUREN",
        key: 'MittelkettigeFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_LANGKETTIGEFETTSAEUREN",
        key: 'LangkettigeFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_GLYCERINUNDLIPOIDE",
        key: 'GlycerinundLipoide',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_CHOLESTEROL",
        key: 'cholesterin',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }
];
export type INutritionType = {
    name: string;
    key: string;
    maxValue: number;
    maxValueFemale19to25?: number;
    maxValueFemale25to51?: number;
    maxValueFemale51to65?: number;
    maxValueFemale65plus?: number;
    maxValueMale19to25?: number;
    maxValueMale25to51?: number;
    maxValueMale51to65?: number;
    maxValueMale65plus?: number;
    OverdosePercentage?: number;
    unit: string;
    isMineral?: boolean;
    isTrace?: boolean;
    isVitamin?: boolean;
    isProtine?: boolean;
    isCarbs?: boolean;
    isFats?: boolean;
    isFiber?: boolean;
}
export const getIngredientUnitString = (unit?: number) => {
    switch (unit) {
        case 0: {
            return "LABEL_ML"
        }
        case 1: {
            return "LABEL_GR"
        }
        case 2: {
            return "LABEL_UNIT"
        }
        case 3: {
            return "LABEL_SLICE"
        }
        case 4: {
            return "LABEL_EL"
        }
        case 5: {
            return "LABEL_TL"
        }
        case 6: {
            return "LABEL_CUP"
        }
        case 7: {
            return "LABEL_PINCH"
        }
        case 8: {
            return "LABEL_CLOVE"
        }
        case 9: {
            return "LABEL_PK";
        }
        case 10: {
            return "LABEL_TUB";
        }
        case 11: {
            return "LABEL_ROLL";
        }
        case 12: {
            return "LABEL_LEAVES";
        }
        case 13: {
            return "LABEL_BUNCH";
        }
        case 14: {
            return "LABEL_HEAD";
        }
        default: {
            return "-"
        }
    }
}
export { NutritionValues };
export const UnitOptions = [
    {
        label: "ml",
        value: 0,
    },
    {
        label: "g",
        value: 1,
    },
    {
        label: "portion",
        value: 2,
    },
    {
        label: "Sl",
        value: 3,
    },
    {
        label: "tbsp",
        value: 4,
    },
    {
        label: "tsp",
        value: 5,
    },
    {
        label: "scoop",
        value: 6,
    },
    {
        label: "pinch",
        value: 7,
    },
    {
        label: "clove",
        value: 8,
    },
    {
        label: "pk",
        value: 9,
    },
    {
        label: "tub",
        value: 10,
    },
    {
        label: "roll",
        value: 11,
    },
    {
        label: "leaves",
        value: 12,
    },
    {
        label: "bunch",
        value: 13,
    },
    {
        label: "head",
        value: 14,
    },
];
